import React from "react";
import fetch from "unfetch";
import Helmet from "react-helmet";
import ApolloClient from "apollo-boost";
import * as Sentry from "@sentry/browser";
import { ApolloProvider } from "react-apollo";

import "./index.css";
import ASASCFAQs from "../components/ASASCComponents/ASASCFAQs";
import ASASCAboutV2 from "../components/ASASCComponents/ASASCAboutV2";
import ASASCHeader from "../components/ASASCComponents/ASASCHeader";
import ASASCIntroV2 from "../components/ASASCComponents/ASASCIntroV2";
import ASASCPartners from "../components/ASASCComponents/ASASCPartners";
import DisqusComments from "../components/ASASCComponents/DisqusComments";
import ASASCApply from "../components/ASASCComponents/ASASCApply";
import pjson from "../../package.json";
import Footer from "../components/Footer";
import OurTeam from "../components/OurTeam";
import Sponsors from "../components/Sponsors";
import EventSchedule from "../components/EventSchedule";

import favicon from "../assets/img/icons/favicon.ico";
import sharingImage from "../assets/img/event_covers/ASASCCover.png";

const ASASCCoverV2 = "https://res.cloudinary.com/astronomerslk/image/upload/v1571287526/ASASC/evpfh7eocy3rrs3dmq7s.png";

export default () => {
  let environment = process.env.NETLIFY_ENV;

  switch (environment) {
    case "deploy-preview":
      environment = "development";
    case "branch-deploy":
      environment = "staging";
    case "production":
      environment = "production";
    default:
      environment = "development";
  }

  if (environment !== "development") {
    Sentry.init({
      environment,
      release: pjson.version,
      dsn: "https://0838f2c5401347329da9bb6f8f28d4e6@sentry.io/1443292"
    });
  }

  const BASE_URL = "https://astronomers.lk/graphql";

  const apolloClient = new ApolloClient({
    uri: BASE_URL,
    fetch
  });


  const disqusShortname = "astronomerslk";

  const disqusConfig = {
    url: "https://astronomers.lk/ASASC2019v2/",
    identifier: "ASASC2019v2",
    title: "2nd All Sri Lanka Asteroid Searching Campaign (ASASC)"
  };

  return (
    <ApolloProvider client={apolloClient}>
      <div css={{ overflowX: "hidden" }}>
        <Helmet
          title="ASASC | 2019 | 2ND ALL-SRI LANKA ASTEROID SEARCH CAMPAIGN"
          meta={[
            {
              name: "google-site-verification",
              content:
                "Urnxreea8HMeUWKtG7dfcfHdospq8p_s6FixHtirFjE"
            },
            {
              name: "description",
              content:
                "2nd ALL Sri Lanka Asteroid Search Campaign"
            },
            {
              name: "keywords",
              content:
                "Astronomy, Research, ARIS, Asteroids search, MPC"
            },
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0"
            },
            {
              name: "twitter:card",
              content: "summary"
            },
            {
              name: "twitter:site",
              content: "@astronomerslk"
            },
            {
              name: "twitter:creator",
              content: "@Sankhalk"
            },
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0"
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:url",
              content: "https://www.astronomers.lk/ASASC2019v2"
            },
            {
              property: "og:title",
              content: "ASASC | ALL-SRI LANKA ASTEROID SEARCH CAMPAIGN"
            },
            {
              property: "og:image",
              content: ASASCCoverV2
            },
            {
              property: "og:description",
              content:
                "Applications for ASASC 2019 are now open!"
            },
            {
              property: "og:site_name",
              content: "astronomers.lk"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "fb:app_id",
              content: "607240259742638"
            }
          ]}
          link={[
            {
              rel: "shortcut icon",
              href: `${favicon}`
            }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>
          <ASASCHeader />
          <ASASCIntroV2 />
          <ASASCAboutV2 />
          <ASASCPartners />
          <ASASCFAQs />
          <DisqusComments disqusShortname={disqusShortname} disqusConfig={disqusConfig} />
          <Footer />
        </div>
      </div>
    </ApolloProvider>
  );
};
