import React from "react";
import { css } from "glamor";

import Header from "./ASASCHeader";
import ActionButton from "../ActionButton";
import ContentWrapper from "../ContentWrapper";

// import ASASCCover from "../../assets/img/event_covers/ASASC_2019_banner.png";
import ISACLogo from "../../assets/img/logo/IASCLogo.png";
import ARISLogo from "../../assets/img/logo/aris.jpg";

import backgroundCubes from "../../assets/img/backgrounds/backgroundCubes.svg";

const ASASCCover =
  "https://res.cloudinary.com/astronomerslk/image/upload/v1571287526/ASASC/evpfh7eocy3rrs3dmq7s.png";

const applyButtonCSS = {
  margin: "25px 0 100px 0",
  "@media(max-width: 820px)": {
    margin: "30px 0 45px auto"
  }
};

const caretCSS = (backgroundColor) =>
  css({
    backgroundColor,
    width: "26px",
    height: "4px"
  });

const ASASCIntroV2 = () => (
  <div
    id="introduction"
    css={{
      width: "100%",
      background: `url(${backgroundCubes}) no-repeat center center`,
      backgroundSize: "cover",
      "@media(max-width: 600px)": {
        minHeight: "600px"
      }
    }}
  >
    {/*<Header standaloneVersion={false} />*/}
    <ContentWrapper>
      <section
        css={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          "@media(max-width: 820px)": {
            textAlign: "center",
            display: "block"
          }
        }}
      >
        <div
          css={{
            "@media(min-width: 1200px)": {
              paddingRight: "50px",
              paddingTop: "100px"
            },
            "@media (min-width: 821px) and (max-width: 1199px)": {
              paddingRight: "50px",
              paddingTop: "100px"
            },
            "@media (max-width: 820px)": {
              paddingTop: "80px"
            }
          }}
        >
          <div
            css={{
              paddingRight: "43px",
              "@media (max-width: 920px)": { paddingRight: "0px" }
            }}
          >
            <h1 css={{ paddingTop: "40px" }}>
              2nd ALL-Sri Lanka Asteroid Search Campaign
            </h1>
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "auto auto 1fr",
                gridColumnGap: "4px",
                padding: "13px 0px 27px 0px",
                "@media(max-width: 820px)": {
                  paddingBottom: "24px"
                },
                "@media(max-width: 1120px)": {
                  display: "none"
                }
              }}
            >
              <div {...caretCSS("#00205b")} />
              <div {...caretCSS("#c81c2e")} />
              <div {...caretCSS("#fedb01")} />
            </div>
          </div>
          <br />
          <img
            src={ASASCCover}
            css={{
              padding: "2rem 1rem",
              width: "100%"
            }}
            alt="ARIS Illustration"
          />

          <h3
            css={{
              padding: "4px 0px 4px 0px",
              color: "#575757",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "24px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "14px",
                lineHeight: "1.6"
              }
            }}
          >
            Selected Teams
          </h3>

          <h2
            css={{
              color: "black",
              lineHeight: "1.2",
              paddingTop: "20px",
              paddingBottom: "20px"
            }}
          >
            <small>
              35 Teams have been selected for the second All-Sri Lanka asteroid
              search campaign. If you are not selected this time, don't be
              discouraged. There will be another campaign in a few months, and
              you will be top contenders for the selections. click below to see the full list of selected teams.
            </small>
          </h2>

          <h2
            data-cy="aris-tagline"
            css={{
              padding: "20px 0 30px",
              color: "#575757",
              fontWeight: 500,
              lineHeight: "30px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "15px",
                lineHeight: "1.6"
              }
            }}
          >
            <strong>
              Update (06 May 2020): Provincial Asteroid Discovered by Team Anandian Astronomical Association.<br />
            </strong>
          </h2>

          <p css={{ lineHeight: "24px", textAlign: "left", fontColor: "#000000", marginBottom: "10px" }}>
            During the campaign citizen scientist teams across the island were able to make 316 PRELIMINARY main-belt asteroids discoveries. Today we got confirmation from IASC one asteroid from the campaign has granted the PROVINCIAL Status.
          </p>
          <p css={{ lineHeight: "24px", fontWeight: "600", textAlign: "left", fontColor: "#000000", marginBottom: "10px" }}>
            <ul>
              <li>
                2019 RQ17
                P10RmFs
                G.D. Punchihewa, R.D. Balasooriya, S. Liyanaarchchi, S. Keerthirathne
                Anandian Astronomical Association
                Sri Lanka
                Provisional
                09/04/19
                AAA0089
              </li>
            </ul>
          </p>
          <p css={{ lineHeight: "24px", textAlign: "left", fontColor: "#000000", marginBottom: "10px" }}>
            This is a wonderful achievement by Sri Lankan researchers since provisional discoveries are very rare in main-belt small asteroids nowadays since most of the large objects have been already discovered. Sri Lanka only had detected only 3 provisional asteroids before Normally there is no detection of provisional observations found in most of the campaigns and only 5 - 20 even fewer numbers are detected by IASC throughout the year. International Astronomical Union Minor Planet Center gives an official designation for each provisional asteroids.
            In layman's terms what this means is that once the orbit of the asteroid is confirmed by a sufficient number of observations researcher who found it can name the asteroid.
          </p>

          <h2
            data-cy="aris-tagline"
            css={{
              padding: "20px 0 30px",
              color: "#575757",
              fontWeight: 500,
              lineHeight: "30px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "15px",
                lineHeight: "1.6"
              }
            }}
          >
            <strong>
              316 New Asteroids in The Solar System were discovered by Sri Lankan research students<br />
            </strong>
          </h2>

          <p css={{ lineHeight: "24px", textAlign: "left", fontColor: "#000000", marginBottom: "10px" }}>
            International Astronomical Search Collaboration (IASC) of Hardin-Simmons University at Texas - USA collaborating with Astronomical Research Institute, Sri Lanka(Local organizer), under the support of the National Aeronautics and Space Administration (NASA) organized 2nd All-Sri Lanka Asteroid Search Campaign (ASASC). It was held from 23rd of August to 20th of September 2019 with the participation of 35 selected teams comprising 170 students, representing different schools, universities and science societies in all over the island.
            <br></br>
          </p>

          <p css={{ lineHeight: "24px", textAlign: "left", fontColor: "#000000", marginBottom: "10px" }}>
            We organized this research based event with leading international organizations for citizen scientists in Sri Lanka in order to give them a practical exposure to analyze scientific data taken from the large astronomical observatories in the world. Many training sessions were conducted by ARIS for participants to get prepared for the campaign successfully.  During the campaign period, participants were able to access data taken from one of the world’s largest astronomical camera and analyzed them using online computer software. It was an invaluable opportunity for the Sri Lankan group of students to participate in such an internationally recognized research campaign through ARIS.
          </p>

          <p css={{ lineHeight: "24px", textAlign: "left", fontColor: "#000000" }}>
            In this research campaign, Sri Lankan students have made a groundbreaking achievement by discovering <strong>316 new main-belt asteroids</strong> in the Solar system. This achievement of Sri Lankan students was highly appreciated by international organizations and the discoveries were scientifically published by IASC. Discovering new asteroids is highly important for solving some unrevealed problems regarding the origin of our solar system, origin of life of earth and for measuring the dangers that could be caused by asteroids to life on Earth.
          </p>

          <h3 css={{
            padding: "16px 0px 4px 0px",
            color: "#575757",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
            "@media(max-width: 820px)": {
              paddingTop: "24px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}>Discoveries</h3>

          <h2 css={{ color: "black", lineHeight: "1.2", paddingTop: "20px", paddingBottom: "20px" }}>
            <small>Here are the all the discoveries made by Sri Lankan Researchers in ASASC 2019. <a href="http://go.astronomers.lk/asasc_02_2019_discoveries" target="_blank">Click here to see the full list</a>.
            </small></h2>

          {/*<ActionButton
          dataCy="login-button"
          backgroundColor="#ffffff"
          foregroundColor="#00205b"
          style={applyButtonCSS}
          link="http://go.astronomers.lk/asasc_02_2019_teams"
          type="rounded"
        >
          SELECTED TEAMS
        </ActionButton>*/}

          <h3
            css={{
              padding: "16px 0px 4px 0px",
              color: "#575757",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "24px",
              "@media(max-width: 820px)": {
                paddingTop: "16px",
                fontSize: "14px",
                lineHeight: "1.6"
              }
            }}
          >
            Useful Links
          </h3>
          <ul
            css={{
              padding: "8px 12px 30px 16px",
              color: "#575757",
              fontWeight: 500,
              lineHeight: "30px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "14px",
                lineHeight: "1.6"
              }
            }}
          >
            <li>
              ARIS Astromectica Training Materials:
              https://github.com/sankhaKarunasekara/ASASC2019
            </li>
            <li>
              Astrometica Home page:
              http://iasc.cosmosearch.org/Home/Astrometrica
            </li>
            <li>
              All Sri Lankan Asteroid Searching Campaign:
              http://iasc.cosmosearch.org/
            </li>
            <li>
              Discoveries & Observations update:
              http://iasc.cosmosearch.org/Home/Discoveries
            </li>
            <li>
              Minor planet center report spec:
              https://minorplanetcenter.net//iau/info/OpticalObs.html
            </li>
            <li>
              Astrometrica FAQ:
              http://wise.ssl.berkeley.edu/documents/IASC%20FAQ.pdf
            </li>
            <li>
              Minor Planet DB Search: https://minorplanetcenter.net/db_search
            </li>
          </ul>

          {/*
          <h2
            data-cy="aris-tagline"
            css={{
              padding: "20px 0 30px",
              color: "#575757",
              fontWeight: 500,
              lineHeight: "30px",
              "@media(max-width: 820px)": {
                paddingTop: "20px",
                fontSize: "15px",
                lineHeight: "1.6"
              }
            }}
          >
            <strong>Astrometrica Training Session by ARIS <br />
            • on <span css={{color: "red"}} >May 29, 2019 at 2100 IST.</span> </strong><br/>
            online training session
            <br />
          </h2>
          <p>
            there will be online astrometrica training session conducted by ARIS will be held on May 29, 2019 at 2100 IST. If you willing to join the session
            please let us know via email. we will send you the details. In this session we are going to discuss following things,
          </p>
          <ol css={{
            padding: "20px 12px 10px 16px",
            color: "#575757",
            fontWeight: 500,
            lineHeight: "30px",
            "@media(max-width: 820px)": {
              paddingTop: "20px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}>
            <li>how to correctly configure astrometrica</li>
            <li>how to identify a true signatures</li>
            <li>how to correctly submit a MPC report</li>
          </ol>
          <p css={{
            padding: "8px 0 24px",
            color: "#575757",
            fontWeight: 500,
            lineHeight: "30px",
            "@media(max-width: 820px)": {
              paddingTop: "20px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}>if you have trouble get started with ISAC training materials, Please use <a target="_black" href="https://github.com/sankhaKarunasekara/ASASC2019">this practice image set & configurating file</a> to get started.</p>

          <h3 css={{
            padding: "4px 0px 4px 0px",
            color: "#575757",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
            "@media(max-width: 820px)": {
              paddingTop: "20px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}>Useful Links</h3>
          <ul css={{
            padding: "20px 12px 30px 16px",
            color: "#575757",
            fontWeight: 500,
            lineHeight: "30px",
            "@media(max-width: 820px)": {
              paddingTop: "20px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}>
            <li>ARIS Astromectica Traning Materials: https://github.com/sankhaKarunasekara/ASASC2019</li>
            <li>Astrometica Home page: http://iasc.cosmosearch.org/Astrometrica.html</li>
            <li>All Sri Lankan Asteroid Searching Campaign: http://iasc.cosmosearch.org/iasc/Sri%20Lanka.html</li>
            <li>Discoveries & Observations update: http://iasc.cosmosearch.org/iasc/discover.html?
            </li>
            <li>Minor planet center report spec: https://minorplanetcenter.net//iau/info/OpticalObs.html</li>
            <li>Astrometrica FAQ: http://wise.ssl.berkeley.edu/documents/IASC%20FAQ.pdf</li>
            <li>Minor Planet DB Search: https://minorplanetcenter.net/db_search</li>
          </ul>

          <h3 css={{
            padding: "4px 0px 4px 0px",
            color: "#575757",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
            "@media(max-width: 820px)": {
              paddingTop: "20px",
              fontSize: "14px",
              lineHeight: "1.6"
            }
          }}>Selected Teams</h3>

          <h2 css={{color: "black", lineHeight: "1.2", paddingTop: "20px", paddingBottom: "20px"}}><small>25 Teams have been selected for the first All-Sri Lanka asteroid search campaign. If you are not selected this time, don't be discouraged. There will be another campaign in a few months, and you will be on considers as top contenders for selections. <a href="http://go.astronomers.lk/asasc_01_2019_teams">Click here to see the full list of selected teams</a>.</small></h2>
        */}
          {/*          <ActionButton
            dataCy="login-button"
            backgroundColor="#ffffff"
            foregroundColor="#00205b"
            style={applyButtonCSS}
            link="http://go.astronomers.lk/asasc_01_2019_teams"
            type="rounded"
          >
            SELECTED TEAMS
</ActionButton> */}
        </div>
      </section>
    </ContentWrapper >
  </div >
);

export default ASASCIntroV2;
